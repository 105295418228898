import React, { useEffect, useState } from 'react';
import { Link, navigate } from 'gatsby';
import { toast } from 'react-toastify';

import Layout from '../../components/layout';
import Seo from '../../components/seo';
import CustomFetch from '../../components/fetch';

export default function PortalPage({ location }) {
    const [form, setForm] = useState({
        username: '',
        password: '',
    });
    const [verify, setVerify] = useState(false);
    const [code, setCode] = useState({
        code1: '',
        code2: '',
        code3: '',
        code4: '',
        code5: '',
        code6: '',
    });

    const handleChange = (e) => {
        if (e) {
            const { name, value } = e.target;

            setForm((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const handleAuth = (email, password, code) => {
        CustomFetch(
            `${process.env.GATSBY_API_URL}/login`,
            'POST',
            {
                two_factor_token: code,
                email: email,
                password: form.password,
            },
            (result) => {
                if (result.error === '') {
                    localStorage.setItem('ofpPortalToken', result.access_token);
                    localStorage.setItem(
                        'ofpUserProfile',
                        JSON.stringify(result.user)
                    );

                    navigate('/portal/dashboard');
                } else {
                    toast.error(String(result.error));
                }
            },
            (error) => {
                toast.error(String(error));
            }
        );
    };

    const handleLogin = (e) => {
        if (e) {
            e.preventDefault();

            let _error = '';

            if (form.username === '') {
                _error += 'Please enter a username.\r\n';
            }

            if (form.password === '') {
                _error += 'Please enter a password.\r\n';
            }

            if (_error === '') {
                let email = form.username;
                if (!form.username.includes('@')) {
                    email = `${form.username}@virtualportal.com.au`;
                }

                CustomFetch(
                    `${process.env.GATSBY_API_URL}/login/checkIp`,
                    'POST',
                    {
                        email: email,
                        password: form.password,
                    },
                    (res) => {
                        if (res.error === '') {
                            if (res.twofactor === true) {
                                setVerify(true);
                            } else {
                                handleAuth(email, form.password, '');
                            }
                        } else {
                            toast.error(res.error);
                        }
                    },
                    (err) => {
                        toast.error(String(err));
                    }
                );
            } else {
                toast.error(String(_error));
            }
        }
    };

    const handleChangeCode = (e) => {
        if (e) {
            const { value, name } = e.target;
            const fieldIndex = name.replace('code', '');
            const maxLength = 1;

            if (value.length <= maxLength) {
                const nextFieldIndex = parseInt(fieldIndex, 10) + 1;

                if (nextFieldIndex <= 6) {
                    const nextSibling = document.querySelector(
                        `input[name=code${nextFieldIndex}]`
                    );

                    if (nextSibling !== null) {
                        if (value.length > 0) {
                            nextSibling.focus();
                        }
                    }
                }
            }

            const updatedCode = { ...code };
            updatedCode[name] = value;

            if (value.length === 0) {
                const previousFieldIndex = parseInt(fieldIndex, 10) - 1;

                if (previousFieldIndex > 0) {
                    const previousSibling = document.querySelector(
                        `input[name=code${previousFieldIndex}]`
                    );

                    if (previousSibling !== null) {
                        previousSibling.focus();
                    }
                }
            }

            setCode(updatedCode);
        }
    };

    const resendCode = (e) => {
        if (e) {
            e.preventDefault();

            CustomFetch(
                `${process.env.GATSBY_API_URL}/login/checkIp`,
                'POST',
                {
                    email: form.username,
                },
                function (result) {
                    if (result.error === '') {
                        toast.success(
                            'A new code has been sent to your mobile, please use it to verify your account.'
                        );

                        setCode(() => ({
                            code1: '',
                            code2: '',
                            code3: '',
                            code4: '',
                            code5: '',
                            code6: '',
                        }));
                    } else {
                        toast.error(String(result.error));
                    }
                }
            );
        }
    };

    useEffect(() => {
        if (
            code.code1 !== '' &&
            code.code2 !== '' &&
            code.code3 !== '' &&
            code.code4 !== '' &&
            code.code5 !== '' &&
            code.code6 !== ''
        ) {
            let two_factor_token =
                code.code1 +
                code.code2 +
                code.code3 +
                code.code4 +
                code.code5 +
                code.code6;

            let email = form.username;
            if (!form.username.includes('@')) {
                email = `${form.username}@virtualportal.com.au`;
            }

            handleAuth(email, form.password, two_factor_token);
        }
    }, [code]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (token) {
            console.info(token);

            localStorage.setItem('ofpPortalToken', token);
            // // Handle the token as needed, e.g., fetch user data or auto-login
            // // For example:
            CustomFetch(
                `${process.env.GATSBY_API_URL}/getProfile`,
                'POST',
                { token },
                (result) => {
                    if (result.error === '') {
                        localStorage.setItem(
                            'ofpUserProfile',
                            JSON.stringify(result.user)
                        );

                        navigate('/portal/dashboard');
                    } else {
                        toast.error(String(result.error));
                    }
                },
                (error) => {
                    toast.error(String(error));
                }
            );
        }
    }, [location.search]);

    return (
        <Layout>
            <Seo title="Portal" description="Client Portal Login" />
            <div className="content">
                <div className="container">
                    <div className="grid">
                        {verify === true ? (
                            <div className="col-lg-12 col-bleed portal--content">
                                <h1>
                                    Client Portal
                                    <span>
                                        Please enter the 2fa code sent to your
                                        mobile number.
                                    </span>
                                </h1>
                                <div className="twofa">
                                    <div>
                                        <input
                                            name="code1"
                                            type="text"
                                            maxLength="1"
                                            value={code.code1}
                                            onChange={handleChangeCode}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            name="code2"
                                            type="text"
                                            maxLength="1"
                                            value={code.code2}
                                            onChange={handleChangeCode}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            name="code3"
                                            type="text"
                                            maxLength="1"
                                            value={code.code3}
                                            onChange={handleChangeCode}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            name="code4"
                                            type="text"
                                            maxLength="1"
                                            value={code.code4}
                                            onChange={handleChangeCode}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            name="code5"
                                            type="text"
                                            maxLength="1"
                                            value={code.code5}
                                            onChange={handleChangeCode}
                                        />
                                    </div>
                                    <div>
                                        <input
                                            name="code6"
                                            type="text"
                                            maxLength="1"
                                            value={code.code6}
                                            onChange={handleChangeCode}
                                        />
                                    </div>
                                </div>
                                <div className="twofa--resend">
                                    <span>
                                        <button onClick={resendCode}>
                                            Resend Another Code
                                        </button>
                                    </span>
                                </div>
                            </div>
                        ) : (
                            <div className="col-lg-12 col-bleed portal--content">
                                <h1>
                                    Client Portal
                                    <span>
                                        Please login with your given credentials
                                        below
                                    </span>
                                </h1>
                                <form onSubmit={handleLogin}>
                                    <div className="login">
                                        <div className="formItem">
                                            <label className="fi__label">
                                                <input
                                                    type="text"
                                                    name="username"
                                                    value={form.username}
                                                    onChange={handleChange}
                                                    placeholder=""
                                                />
                                                <span className="fi__span">
                                                    Username
                                                </span>
                                            </label>
                                        </div>
                                        <div className="formItem">
                                            <label className="fi__label">
                                                <input
                                                    type="password"
                                                    name="password"
                                                    value={form.password}
                                                    onChange={handleChange}
                                                    placeholder=""
                                                />
                                                <span className="fi__span">
                                                    Password
                                                </span>
                                            </label>
                                        </div>
                                        <div className="formItem">
                                            <button
                                                className="btn"
                                                type="submit"
                                            >
                                                Login
                                            </button>
                                        </div>
                                        <div className="formItem fwItem lastItem forgotten">
                                            <span>
                                                Forgot your password?{' '}
                                                <Link to="/portal/forgot">
                                                    Click Here
                                                </Link>
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
}
